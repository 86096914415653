import React, { useEffect, useState } from "react";
import "./Indivisiual.css";
import { SimpleGrid } from "@chakra-ui/react";
import api from "../../utils/ApiMethod";
import { delay, motion, useAnimation } from "framer-motion";

import { Box, Text, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const Pankaj = () => {
  const [pankajPageData, setPankagePageData] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/pankaj");
      setPankagePageData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Mr. Pankaj Kumar Raj | Director cum Secretary | Bihar State Sports
          Authority
        </title>
        <meta
          name="description"
          content="श्री पंकज कुमार राज, निदेशक सह सचिव, बिहार राज्य खेल प्राधिकरण का संदेश: बिहार की ऐतिहासिक खेल परंपरा और नई नीतियों से राज्य की खेल संस्कृति का विकास हो रहा है। बिहार में खेलों को जन अभियान बनाना हमारा लक्ष्य है।"
        />
      </Helmet>
      <SimpleGrid columns={[1, 1, 2, 2]} mt="20" mb="10">
        <motion.Box
          flexShrink={0}
          initial={{
            opacity: 0,
            translateX: -50,
            translateY: 0,
          }}
          whileInView={{
            opacity: 1,
            translateX: 0,
            translateY: 0,
          }}
          transition={{ duration: 0.3, delay: 1 }}
        >
          <Image
            borderRadius="lg"
            px={[5, 10, 20]}
            style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
            src={pankajPageData[0]?.image}
            alt="Woman paying for a purchase"
            h="350px"
            w="100%"
          />
        </motion.Box>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} p="0">
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize={["18", "42"]}
            letterSpacing="wide"
            color="teal.800"
            px={[4, 10, 20]}
          >
            {pankajPageData[0]?.title}
          </Text>

          <Text
            fontWeight="bold"
            textTransform="uppercase"
            align={"right"}
            fontSize={["10", "13"]}
            letterSpacing="wide"
            color="black"
            px={[4, 10, 20]}
          >
            {pankajPageData[0]?.designation}
          </Text>

          <Text
            mt={2}
            color="gray.500"
            align={"left"}
            px={[5, 10, 20]}
            letterSpacing="wide"
            fontWeight="bold"
            py="2"
          >
            {expanded ? (
              <p>{pankajPageData[0]?.paragraph}</p>
            ) : (
              <p>{pankajPageData[0]?.paragraph?.slice(0, 850)}...</p>
            )}
            <button
              onClick={toggleExpand}
              style={{ color: "blue", fontWeight: "500" }}
            >
              {expanded ? "कम पढ़ें" : "और पढ़ें"}
            </button>
          </Text>
        </Box>
      </SimpleGrid>
    </>
  );
};
export default Pankaj;
