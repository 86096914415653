import React, { useState, useEffect } from "react";
import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const DSODirectory = () => {
  const [dso, setDso] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/dso");
      setDso(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  const UpcomingEventData = [
    // {
    //   id: "1",
    //   title: "मशाल - डी0एस0ओ0, डी0पी0ओ0 के लिए प्रशिक्षण",
    //   date: "5 अगस्त 2024"
    // },
    {
      id: "2",
      title: "3rd राजा कर्ण राज्य तीरंदाजी चैंपियनशिप",
      date: " 06-08 August 2024 "
    },
    {
      id: "3",
      title: "3rd नेशनल जैवलिन डे",
      date: "7 अगस्त 2024"
    },
    {
      id: "4",
      title: "मशाल - प्रशिक्षण आरंभ",
      date: "10 अगस्त 2024"
    },
    {
      id: "5",
      title: "बिहार राज्य संभागीय ईस्पोर्ट्स चैम्पियनशिप",
      date: "14-31 अगस्त 2024"
    },
    {
      id: "6",
      title: "एकलव्य प्रतिभा खोज अभियान",
      date: "17-25 अगस्त 2024"
    },
    {
      id: "7",
      title: "मशाल के लिए पोर्टल पर पंजीकरण आरंभ",
      date: "27 अगस्त 2024"
    },
    {
      id: "8",
      title: "खेल सम्मान समारोह 2024",
      date: "29 अगस्त 2024"
    }
  ]
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upcoming Sporting Events | Sports Department Bihar | BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण के आगामी कार्यक्रम: इस पेज पर विभिन्न आगामी खेल आयोजनों और प्रतियोगिताओं की जानकारी उपलब्ध है। यहां राज्य भर में होने वाले खेल कार्यक्रमों के बारे में अद्यतन जानकारी प्राप्त की जा सकती है।"
        />
      </Helmet>



      <Breadcrumb
        pageName="D.S.O-Directory"
        pageTitle="D.S.O-Directory"
        id="#dso-directory"
        img_url="./img/annual khel 01.jpg"
        mobile_banner="./img/annual khel mobile view 576 x600 01.jpg"
      />

      <h1 className="pb-5" style={{ marginTop: "30px", marginBottom: "30px" }}>
        कार्यक्रम / प्रतियोगिता
      </h1>
      <Box>
        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"30px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>क्र.सं.</Th>
                <Th fontSize={"1.5rem"}>कार्यक्रम/ प्रतियोगिता</Th>
                <Th fontSize={"1.5rem"}>आगामी तिथि </Th>
              </Tr>
            </Thead>

            <Tbody>
              {UpcomingEventData?.map((item, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>
                    {item?.title}
                  </Td>
                  <Td>{item?.date} </Td>
                </Tr>
              ))}

              {/*  new added  */}
              {/* <Tr>
                <Td>1</Td>
                <Td>
                  57वीं नेशनल स्कूल एथलेटिक्स, अंडर-17 (बॉयज एंड गर्ल्स)
                  चैंपियनशिप 2023-24 का शेड्यूल
                </Td>
                <Td>06 अप्रैल से 09 अप्रैल, 2024 </Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td> टेबल टेनिस प्रशिक्षण कैंप </Td>
                <Td>
                  {" "}
                  सोमवार से शनिवार, 3:00 बजे अपराह्न से 6:00 बजे संध्या तक{" "}
                </Td>
              </Tr>

              <Tr>
                <Td>3</Td>
                <Td> कबड्डी के लिए महिला खिलाड़ियों का चय </Td>
                <Td> 23-24 फरवरी 2024 </Td>
              </Tr>

              <Tr>
                <Td>4</Td>
                <Td> 67 वां नेशनल स्कूल गेम्स, भारोत्तोलन प्रतियोगिता </Td>
                <Td> 13-16 फरवरी 2024 </Td>
              </Tr>

              <Tr>
                <Td>5</Td>
                <Td> 12 वां नेशनल स्कूल चेस चैंपियनशिप </Td>
                <Td> 06-10 फरवरी 2024 </Td>
              </Tr>

              <Tr>
                <Td>6</Td>
                <Td>
                  {" "}
                  67 वां नेशनल स्कूल गेम्स, (बालक वर्ग) क्रिकेट चैंपियनशिप
                  2023-24{" "}
                </Td>
                <Td>16-17 जनवरी 2024 </Td>
              </Tr>

              <Tr>
                <Td>7</Td>
                <Td> 58वां नेशनल क्रॉस कंट्री चैंपियनशिप 2024</Td>
                <Td>15 जनवरी 2024 </Td>
              </Tr>

              <Tr>
                <Td>8</Td>
                <Td>मेडल लाओ नौकरी पाओ</Td>
                <Td>06 जनवरी 2024</Td>
              </Tr>

              <Tr>
                <Td>9</Td>
                <Td>
                  67वां नेशनल स्कूल गेम्स,सेपक टाकरा (बालक वर्ग)चैंपियनशिप
                  2023-24
                </Td>
                <Td>03-07 जनवरी 2024</Td>
              </Tr>

      

              <Tr>
                <Td>10</Td>
                <Td>नेशनल गेम्स</Td>
                <Td>25 अक्टूबर से 09 नवंबर 2023</Td>
              </Tr>
              <Tr>
                <Td>11</Td>
                <Td>नेशनल स्कूल गेम्स </Td>
                <Td>03 दिसंबर 2023</Td>
              </Tr>

              <Tr>
                <Td>2</Td>
                <Td>प्रो कबड्डी</Td>
                <Td>20 जनवरी 2024</Td>
              </Tr>

              <Tr>
                <Td>12</Td>
                <Td>चेस चैंपियनशिप</Td>
                <Td>जनवरी 2024</Td>
              </Tr>

              <Tr>
                <Td>13</Td>
                <Td>मल्लयुद्ध</Td>
                <Td>12 से 15 दिसंबर 2023</Td>
              </Tr>

              <Tr>
                <Td>14</Td>
                <Td>बिहार वॉलीबॉल प्रीमियर लीग</Td>
                <Td>जनवरी 2023</Td>
              </Tr> */}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default DSODirectory;
