// import React from "react";
import Search from "../assets/images/search.png";
import styles from "../styles/menu.module.css";
import React, { useState } from "react";
import SearchComponent from "./Search"; // Import the Search component
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  Portal,
  Image,
} from "@chakra-ui/react";

const Menu = () => {
  const [results, setResults] = useState([]);

  // Handle search results from the Search component
  const handleResults = (newResults) => {
    setResults(newResults);
  };
  const customStyle = {
    "--i": ".85s", // Convert the CSS custom property to an object property
  };

  const customStyle1 = {
    "--i": ".1.1s", // Convert the CSS custom property to an object property
  };

  const customStyle2 = {
    "--i": "1.35s", // Convert the CSS custom property to an object property
  };

  const customStyle3 = {
    "--i": "1.8s", // Convert the CSS custom property to an object property
  };

  return (
    <div>
      <header>
        <div className={styles.container}>
          <input type="checkbox" name="" className={styles.check} />

          {/* <div className={styles.logo_container}>
            <h3 className={styles.logo}>
              BSSA<span>BIHAR </span>
            </h3>
          </div> */}

          <div className={styles.nav_btn}>
            <div className={styles.nav_links}>
              <ul>
                {/*--------------------- home start---------------------- */}
                <li className={styles.nav_link} style={customStyle}>
                  <a href="/" title="home">
                    <span>
                      {/* <i className="fas fa-home"></i> */}
                      <i class="bi bi-house"></i>
                    </span>
                  </a>
                </li>

                {/*--------------------- home ends---------------------- */}

                {/* ------------------------about us section ----------------- starts     */}
                <li className={styles.nav_link} style={customStyle}>
                  <a>
                    हमारे बारे में<i className="bi bi-chevron-down"></i>
                  </a>

                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/aboutus" title="introduction">
                          परिचय
                        </a>
                      </li>
                      {/*
                      <li className={styles.dropdown_link}>
                        <a href="/" title="Sports legacy">
                          हमारी खेल विरासत
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="/visionandmission" title="Vision and Mission">
                          लक्ष्य, दूरदर्शिता एवं उद्देश्य
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/sportlegecy" title="Sport Legecy">
                          हमारी खेल विरासत{" "}
                        </a>
                      </li>
                      {/* --------------multilevel drop down------------ for sendesh */}

                      <li className={styles.dropdown_link}>
                        <a>
                          संदेश <i className="bi bi-chevron-down"></i>
                        </a>
                        <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            {/* Hide CM, Dupty CM and Sports minister page due to code of conduct */}

                            <li className={styles.dropdown_link}>
                              <a href="/chiefminister" title="Chief Minister">
                                {" "}
                                माननीय मुख्यमंत्री,बिहार
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/deputychiefminister"
                                title="Deputy Chief Minister"
                              >
                                {" "}
                                माननीय उप मुख्यमंत्री, बिहार
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/sports-minister"
                                title="Hon'ble Minister, Department of Art, Culture and Youth, Government of Bihar."
                              >
                                {" "}
                                माननीय मंत्री खेल विभाग, बिहार
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/principal-secretary-sports-department"
                                title="Additional Chief Secretary, Department of Art, Culture and Youth, Government of Bihar."
                              >
                                प्रधान सचिव, खेल विभाग, बिहार{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/ravindran"
                                title="Director General cum CEO Bihar State Sports Authority"
                              >
                                महानिदेशक सह मुख्य कार्यकारी अधिकारी, बिहार
                                राज्य खेल प्राधिकरण{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a
                                href="/pankaj"
                                title="Director cum Secretary Bihar State Sports Authority"
                              >
                                निदेशक सह सचिव, बिहार राज्य खेल प्राधिकरण
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      {/*------------------------------ hello----------------------------- */}

                      {/*-----------------------helloooo- -----------------------  */}
                      {/* <li className={styles.dropdown_link}>
                        <a href="#">
                          Link 3<i className="bi bi-chevron-down"></i>
                        </a>
                        <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 1</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 2</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">Link 3</a>
                            </li>
                            <li className={styles.dropdown_link}>
                              <a href="#">
                                More<i className="bi bi-chevron-down"></i>
                              </a>
                              <div
                                className={`${styles.dropdown} ${styles.second}`}
                              >
                                <ul>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 1</a>
                                  </li>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 2</a>
                                  </li>
                                  <li className={styles.dropdown_link}>
                                    <a href="#">Link 3</a>
                                  </li>
                                  <div className={styles.arrow}></div>
                                </ul>
                              </div>
                            </li>
                            <div className={styles.arrow}></div>
                          </ul>
                        </div>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a
                          href="/organizationalStru"
                          title="Organizational Structure"
                        >
                          संगठनात्मक संरचना
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/prioritysports" title="priority sports">
                          {" "}
                          बिहार के प्राथमिकता वाले खेल
                        </a>
                      </li>

                      {/* <li className={styles.dropdown_link}>
                        <a href="/sports-minister" title="Jitendra sir">
                          {" "}
                          श्री जितेंद्र कुमार राय (माननीय मंत्री, कला,संस्कृति
                          एवं युवा विभाग )
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/principal-secretary-sports-department" title="District Sports Officers">
                          श्रीमती हरजोत कौर बम्हरा भा. प्र. से. अपर मुख्य सचिव
                          (कला,संस्कृति एवं युवा विभाग )
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/ravindran" title="Ravindran sir">
                          {" "}
                          श्री रविन्द्रण शंकरण भा. पु. से. महानिदेशक सह मुख्य
                          कार्यकारी अधिकारी (बिहार राज्य खेल प्राधिकरण ){" "}
                        </a>
                      </li> */}

                      {/* <li className={styles.dropdown_link}>
                        <a href="/pankaj" title="Pankaj sir">
                          {" "}
                          श्री पंकज राज भा. पु. से. निदेशक सह सचिव (बिहार राज्य
                          खेल प्राधिकरण )
                        </a>
                      </li> */}

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="Policies and Schemes">
                    नीति एवं योजना <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          {" "}
                          हमारी नीतियां
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="img/BSSA_Bye_Laws.pdf" target="_blank">
                          {" "}
                          खेल नियम और विनियम
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        {/* <a href="img/Sport_Rule_2023.pdf" target="_blank">
                          {" "}
                          खेल नीति
                        </a> */}
                        <a href="/sportsPolicy" title="Sports  Policy">
                          खेल नीति
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/sportsreqrmtpolicy"
                          title="Sports Recruitment Policy"
                        >
                          {" "}
                          खेल नियुक्ति नीति
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/kss" title="Khel Samman samaroh">
                          खेल सम्मान समारोह
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/sportswelfarefund"
                          title="Sports Welfare Fund"
                        >
                          खिलाड़ी कल्याण कोष
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/grantssportsfederations"
                          title="grants to sports federations"
                        >
                          खेल संघो को अनुदान
                        </a>
                      </li>

                      {/* -----------------------  Comment for temporary ------------------ */}
                      {/* <li className={styles.dropdown_link}>
                        <a
                          href="/sportequiinf"
                          title="Sports-insrtruments-information"
                        >
                          खेल उपकरण संबंधी जानकारी
                        </a>
                      </li> */}
                      <li className={styles.dropdown_link}>
                        <a
                          href="/sportsscholarshippolicy"
                          title="upcoming policies"
                        >
                          खेल छात्रवृति नीति{" "}
                          {/* <i className="bi bi-chevron-down"></i> */}
                        </a>
                      </li>
                      {/* <li className={styles.dropdown_link}>
                        <a href="#" title="upcoming policies">
                          आगामी नीतियां <i className="bi bi-chevron-down"></i>
                        </a>
                        <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a
                                href="/sportsscholarshippolicy"
                                title="Sports Scholarship Policy"
                              >
                                {" "}
                                खेल छात्रवृति नीति{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/inspiration" title="Prerna">
                                प्रेरणा{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/able" title="Saksham">
                                {" "}
                                सक्षम
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                उड़ान
                              </a>
                            </li>

                            
                          </ul>
                        </div>
                      </li> */}

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="our achievements">
                    हमारी उपलब्धियां <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a
                          href="/internationalachievement"
                          title="international achievement"
                        >
                          {" "}
                          अंतर्राष्ट्रीय उपलब्धियां
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a
                          href="/nationalachievement"
                          title="national achievement"
                        >
                          राष्ट्रीय उपलब्धियां
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="/statelevelachievement"
                          title="State level achievements"
                        >
                          राज्य स्तरीय उपलब्धियां
                        </a>
                      </li>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/kheloindiacenter" title="Khelo India Center">
                          खेलो इंडिया केंद्र
                        </a>
                      </li> */}

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                {/* --------------- खेल संस्थाएं ------------- */}
                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="our achievements">
                    खेल संस्थाएं <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="img/eklavya_Kendra.pdf" target="_blank">
                          {" "}
                          एकलव्य केंद्र
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="img/Kic.pdf" target="_blank">
                          {" "}
                          खेलो इंडिया केंद्र{" "}
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a
                          href="img/khel_bhawan_summary_new .pdf"
                          target="_blank"
                        >
                          {" "}
                          खेल भवन
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="img/Training_Center.pdf" target="_blank">
                          {" "}
                          प्रशिक्षण केंद्र
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>
                {/*99999999999999999999999999999 */}

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="/tenders" title="tenders">
                    निविदाएं
                  </a>
                  {/* <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/tenders" title="Tenders">
                          निविदाएं
                        </a>
                      </li>


                  {/* <li className={styles.dropdown_link}>
                        <a href="/recruitmentnotices" title="33 Khelo">
                          {" "}
                          बिहार में 33 खेलो इंडिया केंद्रों के लिए प्रशिक्षकों
                          की नियुक्ति संबंधी सूचनाएं - 3 जुलाई
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a
                          href="/registrationforkss"
                          title="Resistration for Khel Samman Samaroh"
                        >
                          खेल सम्मान समारोह 2023 के लिए पंजीकरण
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          मेडल लाओ नौकरी पाओ
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/policy" title="Our Policies">
                          मेडल लाओ नौकरी पाओ
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/sportsbuget" title="Sports budget">
                          खेल बजट
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="#" title="Our Policies">
                          खेल संबंधी आधारभूत संरचनाएं
                        </a>
                      </li> */}

                  {/* <li className={styles.dropdown_link}>
                        <a href="/upcommingevents" title="Upcomming events">
                          आगामी आयोजन
                        </a>
                      </li> */}

                  {/* <div className={styles.arrow}></div>
                    </ul>
                  </div> */}
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="gallery">
                    गैलरी <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/gallery" title="Events Gallery">
                          आयोजनों की प्रस्तुति
                        </a>
                      </li> */}
                      <li className={styles.dropdown_link}>
                        <a href="/GalleryPage" title="photos">
                          तस्वीरें
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/videos" title="videos">
                          विडियो
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="/eventsAndPrograms" title="events and programs">
                    आयोजन एवं कार्यक्रम
                    <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/eventorg" title="upcoming policies">
                          आयोजित कार्यक्रम
                        </a>
                        {/* <div className={`${styles.dropdown} ${styles.second}`}>
                          <ul>
                            <li className={styles.dropdown_link}>
                              <a
                                href="/sportsscholarshippolicy"
                                title="Sports Scholarship Policy"
                              >
                                {" "}
                                स्पोर्ट्स कॉन्क्लेव{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/inspiration" title="Prerna">
                                स्पोर्ट्स कॉन्क्लेव 2.0{" "}
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/able" title="Saksham">
                                {" "}
                                खेल सम्मान समारोह <i className="bi bi-chevron-down"></i>
                              </a>
                              <div className={`${styles.dropdown} ${styles.second}`}>
                                <ul>

                                <li className={styles.dropdown_link}>
                                    <a href="/kss2023" title="Udaan">
                                      {" "}
                                      खेल सम्मान समारोह 2023

                                    </a>
                                  </li>

                                  <li className={styles.dropdown_link}>
                                    <a href="/kss2022" title="Saksham">
                                      {" "}
                                      खेल सम्मान समारोह 2022
                                    </a>
                                  </li>
                                </ul>
                              </div>

                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                राजा कर्ण तीरंदाजी  (लक्ष्य)

                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                दक्ष

                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                तरंग
                              </a>
                            </li>

                            <li className={styles.dropdown_link}>
                              <a href="/udaan" title="Udaan">
                                {" "}
                                नीडजैम 2023

                              </a>
                            </li>

                          </ul>
                        </div> */}
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/upcomingevents" title="">
                          आगामी कार्यक्रम
                        </a>
                      </li>

                      {/* <li className={styles.dropdown_link}>
                        <a href="/" title="">
                          शीघ्र प्रारंभ होने वाली खेल नीतियाँ
                        </a>
                      </li> */}
                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle2}>
                  <a href="#" title="download">
                    डाउनलोड <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/notices" title="Notices & Circulars">
                          {" "}
                          सूचनाएं एवं परिपत्र
                        </a>
                      </li>
                      {/* <li className={styles.dropdown_link}>
                        <a href="/press-release" title="Press release">
                          प्रेस विज्ञप्ति
                        </a>
                      </li> */}

                      <li className={styles.dropdown_link}>
                        <a href="/letters" title="letters">
                          पत्र
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a href="/advertisements" title="Advertisement">
                          विज्ञापन
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a href="/annual-calender" title="annual calendar">
                          {/* <a href="/sportsPolicy" title="annual calendar"> */}
                          वार्षिक कैलेंडर
                        </a>
                      </li>

                      <li className={styles.dropdown_link}>
                        <a href="/annual-report" title="annual report">
                          वार्षिक प्रतिवेदन
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li>

                <li className={styles.nav_link} style={customStyle}>
                  <a href="/careerandopportunity" title="DSO Directory">
                    नौकरियां
                  </a>
                </li>
                {/* <li className={styles.nav_link} style={customStyle2}>
                  <a title="Our Contact">
                    हमारा संपर्क <i className="bi bi-chevron-down"></i>
                  </a>
                  <div className={styles.dropdown}>
                    <ul>
                      <li className={styles.dropdown_link}>
                        <a href="/directory" title="directory">
                          {" "}
                          निर्देशिका
                        </a>
                      </li>
                      <li className={styles.dropdown_link}>
                        <a href="/contactus" title="Leave Message/Question">
                          {" "}
                          संदेश / प्रश्न छोड़ें
                        </a>
                      </li>

                      <div className={styles.arrow}></div>
                    </ul>
                  </div>
                </li> */}

                <li className={styles.nav_link} style={customStyle}>
                  <a href="/dsodirectory" title="DSO Directory">
                    डी.एस.ओ निर्देशिका
                  </a>
                </li>

                {/* ------------------------- search item----------------- */}
                <li className={styles.nav_link} style={customStyle}>
                  <a>
                    <Popover>
                      <PopoverTrigger>
                        <div className={styles.search}>
                          <img
                            src={Search}
                            style={{
                              height: "20px",
                              color: "white",
                            }}
                          />
                        </div>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />

                          <PopoverBody>
                            <input
                              type="search"
                              id="form1"
                              class="form-control"
                              placeholder="Search"
                            />
                            {/* <SearchComponent onResults={handleResults} />
                            {results.length > 0 && (
                              <ul className={styles.searchResults}>
                                {results.map((result, index) => (
                                  <li key={index}>
                                    <a href={result.url || "#"}>
                                      {result.title}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )} */}
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </a>
                </li>
              </ul>

              {/*
              <li className={styles.nav_link} style={customStyle2}>
                  <a href="/tenders">
                  {SearchIcon}
                  </a>
                </li> */}
            </div>

            {/* <div className={styles.log_sign} style={customStyle3}>
              <a href="#" className={`${styles.btn} ${styles.transparent}`}>
                Log in
              </a>
              <a href="#" className={`${styles.btn} ${styles.solid}`}>
                Sign up
              </a>
            </div> */}
          </div>

          <div className={styles.hamburger_menu_container}>
            <div className={styles.hamburger_menu}>
              <div></div>
            </div>
          </div>
        </div>
      </header>

      {/* <main>
        <section>
          <div className="overlay"></div>
        </section>
      </main> */}
    </div>
  );
};

export default Menu;
