import React from "react";

const Banner = () => {
  return (
    <>
      <section class="bannerSection">
        <div
          id="bannerSection-Carousel"
          class="carousel slide margin"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#bannerSection-Carousel"
              data-slide-to="0"
              class="active"
            ></li>
            <li
              data-target="#bannerSection-Carousel"
              data-slide-to="1"
              class="active"
            ></li>
            <li
              data-target="#bannerSection-Carousel"
              data-slide-to="2"
              class=""
            ></li>

            <li data-target="#bannerSection-Carousel" data-slide-to="3"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="4"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="5"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="6"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="7"></li>
            {/* <li data-target="#bannerSection-Carousel" data-slide-to="5"></li> */}
            {/* <li data-target="#bannerSection-Carousel" data-slide-to="6"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="7"></li>
            <li data-target="#bannerSection-Carousel" data-slide-to="8"></li> */}
          </ol>

          {/* --------------------RugBy Banner---------------------- */}
          <div class="carousel-inner">
            <div class="item active">
              <a
                href="/"
                title="ShreyeasiSinghbanner"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  // src="img/MobileViewACT.jpeg"
                  // src="img/act_new_banner_mobile_view.jpeg"
                  src="img/For BSSA Website Home page Banner 03 mobile Rendered size.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
              </a>
              <a href="/" title="ticketgenie" target="_blank" rel="noreferrer">
                {/* I have changed here */}

                <img
                  // src="img/DestopViewACT.jpeg"
                  // src="img/act_new_banner_desktop_view.jpeg"
                  src="img/BSSA Website Home page Banner 03 Destop Intrinsic size.jpg"

                  alt="banner-img"
                  class="other__banner"
                />
              </a>
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    <div class="carousel-caption__areaInner"></div>
                    <p class="carousel-caption__areaText"></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="item">
              <a
                href="https://kss.biharsports.org/auth/login?next=/"
                target="_balnk"
              >
                <img
                  // src="img/Mobile size.jpg"
                  // src="img/olympic_banner_mobile.jpeg"
                  src="img/shailesh kumar mobile view.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
                {/* <img src="img/Hockeystadium.jpg" alt="banner-img" class="other__banner" /> */}
                <img
                  // src="img/website size.jpg"
                  // src="img/olympic_banner_desktop.jpeg"
                  src="img/Destopshailesh kumarbaneer.jpg"
                  alt="banner-img"
                  class="other__banner"
                />
                <div class="container">
                  <div class="bannercarousel carousel-caption">
                    <div class="carousel-caption__area animated bounceInRight slower"></div>
                  </div>
                </div>
              </a>
            </div>
            <div class="item">
              <img
                src="img/rugby mobile view.jpg"
                alt="banner-img"
                class="mob__banner"
              />
              {/* <img src="img/Hockeystadium.jpg" alt="banner-img" class="other__banner" /> */}
              <img
                src="img/rugby banner web.jpg"
                alt="banner-img"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    {/* <div class="carousel-caption__areaInner">
                    <h2 class="carousel-caption__areaTitle">Kalinga Hockey Stadium</h2>
                  </div>
                  <p class="carousel-caption__areaText">
                    Revamped in 2018, just before the Men’s Hockey World Cup 2018, Kalinga Hockey Stadium was
                    dedicated to the people by Hon’ble Chief Minister Sri Naveen Patnaik.
                  </p>  */}
                  </div>
                </div>
              </div>
            </div>

            <div class="item ">
              <img
                src="./img/medallaonaukaripaowcm_MobileView.jpeg"
                alt="banner-img"
                class="mob__banner"
              />
              {/*<img src="img/Footballstadium.jpg" alt="banner-img" class="other__banner" /> */}
              <img
                src="img/medallaonaukaripaowcm_destopView.jpeg"
                alt="Chief-minister bihar"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower"></div>
                </div>
              </div>
            </div>

            {/* new banner from here  */}

            {/* <div class="item">
              <a href="/" title="Tejaswai Mantri ji">
                <img
                  src="img/tejaswi-yadav-mobile-banner.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
              </a>
              <a href="/" title="ticketgenie">

                <img
                  src="img/tejaswaiji-desktop-banner.jpg"
                  alt="banner-img"
                  class="other__banner"
                />
              </a>
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    <div class="carousel-caption__areaInner">
                    </div>
                    <p class="carousel-caption__areaText">
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div class="item">
              <a
                href="/"
                title="Jitendra Mantri ji"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/mantri-ji-newbanner-mobile.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
              </a>
              <a href="/" title="ticketgenie" target="_blank" rel="noreferrer">

                <img
                  src="img/jitendra-mantriji-desktop-bannernewsize.jpg"
                  alt="banner-img"
                  class="other__banner"
                />
              </a>
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    <div class="carousel-caption__areaInner">
                    </div>
                    <p class="carousel-caption__areaText">
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            {/*   kon si  */}

            <div class="item">
              <a
                href="/"
                title="ShreyeasiSinghbanner"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/ShreyeasiSinghbannermobile.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
              </a>
              <a href="/" title="ticketgenie" target="_blank" rel="noreferrer">
                {/* I have changed here */}

                <img
                  src="img/shreyasi-desktop-banner.jpg"
                  alt="banner-img"
                  class="other__banner"
                />
              </a>
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    <div class="carousel-caption__areaInner"></div>
                    <p class="carousel-caption__areaText"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* ))))))))))))))))))))))))))) */}

            <div class="item">
              <a href="/" title="khel virasat" target="_blank" rel="noreferrer">
                <img
                  // src="img/our_legacy_homepage_mobile_banner.jpeg"
                  src="img/khel_virsat_mobilebanner.jpg"
                  alt="banner-img"
                  class="mob__banner"
                />
              </a>
              <a href="/" title="ticketgenie" target="_blank" rel="noreferrer">
                {/* I have changed here */}

                <img
                  // src="img/khel-virsat-desktop-banner.jpg"
                  src="img/khel_virsat_Destop.jpg"
                  alt="banner-img"
                  class="other__banner"
                />
              </a>
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    {/* <div
                      class="carousel-caption__areaInner"
                      style={{ backgroundColor: "none", textAlign: "left" }}
                    >
                      <h2
                        class="carousel-caption__areaTitle"
                        style={{
                          fontSize: "50px",
                          lineHeight: "20px",
                          background: "none",
                          color: "#09477e",
                          marginLeft: "-35px",
                          textAlign: "left",
                        }}
                      >
                        बिहार के उभरते सितारे <br />
                        <br />
                        बिहार को दिला रहें हैं <br />
                        <br />
                        शानदार जीत
                      </h2>
                    </div> */}
                    <p
                      class="carousel-caption__areaText"
                      style={{ color: "#09477e" }}
                    >
                      {/* बिहार की खेल यात्रा */}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* new banner end here */}

            {/* Collage banner from here */}

            <div class="item">
              {/* <img
                src="img/athelete_collage_mobile_banner.jpg"
                alt="banner-img"
                class="mob__banner"
              /> */}
              <img
                src="img/player cllge mobile viewRevised.jpg"
                alt="banner-img"
                class="mob__banner"
              />
              {/* <img src="img/Hockeystadium.jpg" alt="banner-img" class="other__banner" /> */}
              {/* <img
                src="img/collage-new-desktop-banner.jpg"
                alt="banner-img"
                class="other__banner"
              /> */}
              <img
                src="img/collage-new-desktop-bannerRevised.jpg"
                alt="banner-img"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    {/* <div class="carousel-caption__areaInner">
                    <h2 class="carousel-caption__areaTitle">Kalinga Hockey Stadium</h2>
                  </div>
                  <p class="carousel-caption__areaText">
                    Revamped in 2018, just before the Men’s Hockey World Cup 2018, Kalinga Hockey Stadium was
                    dedicated to the people by Hon’ble Chief Minister Sri Naveen Patnaik.
                  </p>  */}
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------------------------------Nineteenth banner ---------------------------------- */}

            <div class="item">
              <img
                src="img/mobile_view_asian_games_mobile_banner.jpg"
                alt="banner-img"
                class="mob__banner"
              />
              {/* <img src="img/Hockeystadium.jpg" alt="banner-img" class="other__banner" /> */}
              <img
                src="img/asian-game-new-desktop-banner.jpg"
                alt="banner-img"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    {/* <div class="carousel-caption__areaInner">
                    <h2 class="carousel-caption__areaTitle">Kalinga Hockey Stadium</h2>
                  </div>
                  <p class="carousel-caption__areaText">
                    Revamped in 2018, just before the Men’s Hockey World Cup 2018, Kalinga Hockey Stadium was
                    dedicated to the people by Hon’ble Chief Minister Sri Naveen Patnaik.
                  </p>  */}
                  </div>
                </div>
              </div>
            </div>

            {/*------------------------------------ Stadium banner ----------------------------------- */}

            {/* <div class="item">
              <img
                src="img/patliputra-stadium-mobile-banner.jpg"
                alt="banner-img"
                class="mob__banner"
                style={{ opacity: 0.7 }}
              />

              <img
                src="img/patliputra-stadium-desktop-banner-new.jpg"
                alt="banner-img"
                class="other__banner"
                style={{ opacity: 0.7 }}
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                    <div class="carousel-caption__areaInner">
                      <h2
                        class="carousel-caption__areaTitle "
                        style={{
                          backgroundColor: "transparent",
                          fontSize: "40px",
                          color: "#01519a",
                        }}
                      >
                        पाटलिपुत्र खेल परिसर <br />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* --------------------------Nidjam banner------------------------------------------ */}

            {/* <div class="item">
              <img
                src="img/Nidjam-web-collage-mobilebanner.jpg"
                alt="banner-img"
                class="mob__banner"
              />
              <img
                src="img/nidjam-dektop-banner.jpg"
                alt="banner-img"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div class="item">
              <img
                src="img/banner-mob-dilsekhelo.jpg"
                alt="banner-img"
                class="mob__banner"
              />
            
              <img
                src="img/dilsekhelo-desktop-banner.jpg"
                alt="banner-img"
                class="other__banner"
              />
              <div class="container">
                <div class="bannercarousel carousel-caption">
                  <div class="carousel-caption__area animated bounceInRight slower">
                  
                  </div>
                </div>
              </div>
            </div> */}

            {/*  <div class="item">
            <img src="img/indoor-mob.jpg" alt="banner-img" class="mob__banner" />
            <img src="img/indoorstadiumnew.jpg" alt="banner-img" class="other__banner" />
            <div class="container">
              <div class="bannercarousel carousel-caption">
                <div class="carousel-caption__area animated bounceInRight slower">
                  <div class="carousel-caption__areaInner">
                    <h2 class="carousel-caption__areaTitle">Jawaharlal Nehru <br/> Indoor Stadium, Cuttack</h2>
                  </div>
                  <p class="carousel-caption__areaText">
                    East India's second and Odisha's only indoor arena, Jawaharlal Nehru Indoor Stadium,
                    Cuttack was established in March, 1987. It has hosted several national and international
                    tournaments including Commonwealth Table Tennis Championships 2019.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

            {/*<div class="item">
                  <img src="img/talent-bg-mob.jpg" alt="banner-img" class="mob__banner"/>
                  <img src="img/talent-sport-banner.png" alt="banner-img"   class="other__banner"/>
                  <div class="container">
                     <div class="bannercarousel carousel-caption">
                        <div class="carousel-caption__area animated bounceInRight slower">

                        </div>
                     </div> */}
          </div>
        </div>

        {/* <div class="item">
                  <img src="img/khelo__india.jpg" alt="banner-img" class="mob__banner"/>
                  <img src="img/khelo-india.jpg" alt="banner-img"   class="other__banner"/>
                  <div class="container">
                     <div class="bannercarousel carousel-caption">
                        <div class="carousel-caption__area animated bounceInRight slower">
                           <div class="carousel-caption__areaInner">
                              <h2 class="carousel-caption__areaTitle">Jawaharlal Nehru<br/> Indoor Stadium, Cuttack</h2>
                           </div>
                           <p class="carousel-caption__areaText">
                             East India's second and Odisha's only indoor arena, Jawaharlal Nehru Indoor Stadium, Cuttack was established in March, 1987.
                           </p>
                        </div>
                     </div>
                  </div>
               </div> */}
        {/* </div> */}
        {/* <div class="timer">



            <div id="countdown">
                <ul class="countdown__list">
                <li class=""><span id="days"></span>Days to Go</li>
                <li><span id="hours"></span>Hours</li>
                <li><span id="minutes"></span>Minutes</li>
                <li><span id="seconds"></span>Seconds</li>-- >
                </ul>



            </div>
            </div>*/}
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        {/* </div> */}
      </section>
    </>
  );
};
export default Banner;
